import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';

import Home from './pages/Home';
import Detalle from './pages/Detalle';
import Testing from './pages/Testing';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/usados/:id" element={<Detalle />} />
        <Route path="/testing" element={<Testing />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

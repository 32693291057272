import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import DetalleCarousel from '../components/detalle/DetalleCarousel.js';
import DetalleInfo from '../components/detalle/DetalleInfo.js';
import DetalleForm from '../components/detalle/DetalleForm.js';
import useFetchUsado from '../components/detalle/useFetchUsado.js';
import Related from '../components/Related.js';
import Informations from '../components/home/Informations'

const Detalle = () => {
  const { id } = useParams();
  const { usado, isLoading } = useFetchUsado(id);

  const [listUsed, setListUsed] = useState(null);
  const getUsed = async () => {
    // estoy obteniendo solo los 3 ultimos usados
    const response = await fetch('https://doncar.com.ar/admin/wp-json/api/v1/used_cars?per_page=3');
    const data = await response.json();
    setListUsed(data);
  };
  useEffect(() => {
    getUsed();
  }, []);

  return (
    <section className="detalle">
      <header className="detalle-header container">
        <div className="detalle-header__title">
          <h1 className='h1'>¡Tenemos <strong>un usado para vos</strong>!</h1>

          <Link className='link' to='/usados'><span>{'<-'}</span> Ver todos los autos</Link>
        </div>
      </header>

      {
        isLoading ? (
          <div className="loading">
            Cargando...
          </div>
        ) : (
          <div className="detalle-body container">
            <DetalleCarousel usado={usado} isLoading={isLoading} />
            <DetalleInfo usado={usado} isLoading={isLoading} />
            <DetalleForm usado={usado} />
          </div>
        )
      }
      <div className='detalle-extras'>
        {
          listUsed &&
          <Related
            htmlTitle={`¡Estos usados pueden <br /> <strong>interesarte</strong>!`}
            colorTitle={'c-dark'}
            used={listUsed}
          />
        }
        <Informations />
      </div>
    </section>
  );
};

export default Detalle;
import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchUsado = (usadoID) => {
  const [usado, setUsado] = useState({});
  let [isLoading, setIsLoading] = useState(true);
  let [hasError, setHasError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://doncar.com.ar/admin/wp-json/wp/v2/used_cars/${usadoID}`;
        const response = await axios.get(url);
        setUsado(response.data);
      } catch (error) {
        setHasError(true)
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData()
  }, []);

  return {
    usado,
    isLoading,
    hasError
  };
};

export default useFetchUsado;

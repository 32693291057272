import React from 'react';
import Slider from './Slider';
import Vehicle from './Vehicle';
import useWindowDimensions from './Dimensions';

function Related({ htmlTitle, colorTitle, used }) {

  const { width } = useWindowDimensions();

  return (
    <section className="related lg:flex">
      <div className="related__text lg:basis-1/4 mb-8 lg:mb-auto">
        <h3 className={`h2 text-center md:text-left f-light ${colorTitle}`} dangerouslySetInnerHTML={{ __html: htmlTitle }}></h3>
      </div>
      <div className="related__carousel lg:basis-3/4 flex md:overflow-auto xl:overflow-hidden">
        {
          width <= 768
            ?
            <Slider related={true} used={used} />
            :
            <>
              {
                used && used.map((item, index) => (
                  <Vehicle
                    key={index}
                    image={item.featured_image.full}
                    name={item.model}
                    price={item.price}
                    version={item.version}
                    link={'#'}
                    basis={'md:basis-1/2 lg:basis-1/3 car--carousel'}
                  />
                ))
              }
            </>
        }
      </div>
    </section>
  );
}

export default Related;
import React, { useState, useEffect } from 'react'
import CallToActions from '../components/CallToActions'
import Informations from '../components/home/Informations'
import Related from '../components/Related'
import Sell from '../components/Sell'
import Testimonials from '../components/Testimonials'

const Testing = () => {

  // BLOQUE DE AUTOS RELACIONADOS
  const [ listUsed, setListUsed ] = useState(null)
  const getUsed = async () => {
    // estoy obteniendo solo los 3 ultimos usados
    const response = await fetch('https://doncar.com.ar/admin/wp-json/api/v1/used_cars?per_page=3')
    const data = await response.json()
    setListUsed(data)
  }
  useEffect(() => {
    getUsed()
  }, [])

  return (
    <section>
      
      {/* BARRA CELESTE CON 4 ITEMS (EN REALIDAD QUEDARON 3 NO MÁS) */}
      <Informations />

      {/* BLOQUE DE AUTOS RELACIONADOS */}
      {
        listUsed &&
        <Related
          htmlTitle={`¡Estos usados pueden <br /> <strong>interesarte</strong>!`}
          colorTitle={'c-dark'}
          used={listUsed}
        />
      }

      {/* BLOQUE DE 3 BOTONES AZULES */}
      <CallToActions />

      {/* BLOQUE ¿QUERÉS VENDER TU USADO? */}
      <Sell />

      {/* BLOQUE TESTIMONIOS */}
      <Testimonials />


    </section>
  )
}

export default Testing;
import React from "react"

function Sell (){

  const handleScroll = ( direction ) => {
    const steps = document.querySelector(".steps")
    if ( direction === "right"){
      steps.scrollLeft = steps.scrollWidth
    } else {
      steps.scrollLeft = 0
    }
  }

  return (
    <section className="sell flex bg-dark">
      <div className="sell__image lg:basis-1/2">
        <img
          className="w-full block"
          src="../images/doncar-queres-vender-tu-usado.png"
          alt="¿Querés vender tu usado?"
        />
      </div>
      <div className="sell__text bg-dark lg:basis-1/2">
        <h2 className="h2 c-light">
          ¿Querés <strong>vender</strong><br />tu usado?
        </h2>
        <p className="c-white f-light w-4/5 lg:w-2/3 xl:w-1/2">
          Traenos tu vehículo para peritarlo y corroborar el estado de la unidad. Le damos a tu auto o camioneta el valor que merece.
        </p>
        <div className="relative">
          <ul className="buttons absolute">
            <li className="buttons--left" onClick={() => handleScroll('left')}>
              <img className="block" src="../images/arrow-light-left.png"/>
            </li>
            <li className="buttons--right" onClick={() => handleScroll('right')}>
              <img className="block" src="../images/arrow-light-right.png"/>
            </li>
          </ul>
        </div>
        <div className="steps flex overflow-x-scroll">
          <div className="steps__item radius-top-small radius-bottom-small">
            <figure className="">
              <img
                className="block m-auto"
                src="../images/input-cursor-text-1.png"
                alt="Paso para vender tu usado #1"
              />
            </figure>
            <h5 className="c-light f-regular text-center">Paso para vender<br />tu usado #1</h5>
            <p className="c-white f-light text-center">
              Contactanos y coordinaremos una visita para poder chequear el estado de la unidad.
            </p>
          </div>

          <div className="steps__item radius-top-small radius-bottom-small">
            <figure className="">
              <img
                className="block m-auto"
                src="../images/calendar2-check-1.png"
                alt="Paso para vender tu usado #2"
              />
            </figure>
            <h5 className="c-light f-regular text-center">Paso para vender<br />tu usado #2</h5>
            <p className="c-white f-light text-center">
              Te realizamos una oferta por tu Vehículo.
            </p>
          </div>

          <div className="steps__item radius-top-small radius-bottom-small">
            <figure className="">
              <img
                className="block m-auto"
                src="../images/calendar2-check-2.png"
                alt="Paso para vender tu usado #1"
              />
            </figure>
            <h5 className="c-light f-regular text-center">Paso para vender<br />tu usado #1</h5>
            <p className="c-white f-light text-center">
              Si la oferta resulta satisfactoria, te solicitamos la documentación necesaria para cerrar la operación.
            </p>
          </div>
        </div>

        <div className="sell__text__button btn c-white bg-light radius-top-medium radius-bottom-medium f-regular text-center">
          Solicitar cotización
        </div>
      </div>
    </section>
  )
}

export default Sell
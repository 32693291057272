import React, { useState, useEffect } from 'react';
import HomeCarousel from '../components/home/HomeCarousel';
import HomeLista from '../components/home/HomeLista';
import CallToActions from '../components/CallToActions';
import Informations from '../components/home/Informations';
import Related from '../components/Related';
import Sell from '../components/Sell';
import Testimonials from '../components/Testimonials';

import "react-responsive-carousel/lib/styles/carousel.min.css";

const Home = () => {
  // BLOQUE DE AUTOS RELACIONADOS
  const [listUsed, setListUsed] = useState(null);
  const getUsed = async () => {
    // estoy obteniendo solo los 3 ultimos usados
    const response = await fetch('https://doncar.com.ar/admin/wp-json/api/v1/used_cars?per_page=3');
    const data = await response.json();
    setListUsed(data);
  };
  useEffect(() => {
    getUsed();
  }, []);

  return (
    <section className='home'>
      <HomeCarousel />

      {/* BARRA CELESTE CON 4 ITEMS (EN REALIDAD QUEDARON 3 NO MÁS) */}
      <Informations />

      {/* BLOQUE DE 3 BOTONES AZULES */}
      <CallToActions />

      <HomeLista />

      {/* BLOQUE ¿QUERÉS VENDER TU USADO? */}
      <Sell />

      {/* BLOQUE TESTIMONIOS */}
      <Testimonials />
    </section>
  );
};

export default Home;
import React from 'react';

const DetalleCarouselVideo = () => {
  return (
    <div className="video">
      <video controls>
        <source src='http://techslides.com/demos/sample-videos/small.mp4' type='video/mp4' />
      </video>
    </div>
  );
};

export default DetalleCarouselVideo;
import React, { Fragment } from 'react';
import Vehicle from '../Vehicle';
import useFecthUsados from './useFetchUsados';

const HomeLista = () => {
  const {
    vehicles,
    vehiclesPagination,
    setVehiclesPagination,
    fetchData,
    isLoading,
    hasError
  } = useFecthUsados();

  return (
    <div className="home-lista">
      <div className="container text-center">
        <h2 className="h2 mb-8" id='lista-header'>¡Tenemos <strong>un usado para vos</strong>!</h2>

        <Filtros />

        {
          isLoading ? (
            <div className="loading text-center font-b">Cargando...</div>
          ) : (
            hasError ? (
              <div className="error text-center font-b">Error al traer datos del servidor</div>
            ) : (
              <Vehiculos
                vehicles={vehicles}
                pagination={vehiclesPagination}
                setVehiclesPagination={setVehiclesPagination}
                fetchData={fetchData}
              />
            )
          )
        }
      </div>
    </div>
  );
};

const FiltrosMobile = () => {
  return (
    <div className="filtros-mobile">
      <div className="filtros-mobile__botones">
        <button className="boton">
          <span className="icon">
            <img src="/images/icons/filters-teal.png" alt="Filtrar" />
          </span>
          Filtrar
        </button>
        <button className="boton">
          <span className="icon">
            <img src="/images/icons/heart-teal.png" alt="Filtrar" />
          </span>
          Ver autos guardados
        </button>
      </div>
    </div>
  );
};

const FiltrosDesktop = () => {
  return (
    <div className="filtros-desktop">
      <div className="filtros-desktop__option">
        <select className='select' name="" id="">
          <option value="" selected>Categorías</option>
          <option value="">Unknown</option>
        </select>
      </div>
      <div className="filtros-desktop__option">
        <select className='select' name="" id="">
          <option value="" selected>Precios</option>
          <option value="">Unknown</option>
        </select>
      </div>
      <div className="filtros-desktop__option">
        <select className='select' name="" id="">
          <option value="" selected>Ford, Volksw...</option>
          <option value="">Unknown</option>
        </select>
      </div>
      <div className="filtros-desktop__option">
        <select className='select' name="" id="">
          <option value="" selected>Kilómetros</option>
          <option value="">Unknown</option>
        </select>
      </div>
      <div className="filtros-desktop__option">
        <select className='select' name="" id="">
          <option value="" selected>Años</option>
          <option value="">Unknown</option>
        </select>
      </div>
      <div className="filtros-desktop__option">
        <button className='boton'>Aplicar Filtros</button>
      </div>
    </div>
  );
};

const Filtros = () => {
  return (
    <div className="home-lista__filtros">
      <FiltrosMobile />
      <FiltrosDesktop />
    </div>
  );
};

const Vehiculos = ({ vehicles, pagination, setVehiclesPagination, fetchData }) => {
  const prevIsDisabled = pagination.page <= 1
  const nextIsDisabled = pagination.page >= pagination.total_pages

  const changePage = async (nextPage) => {
    await fetchData(nextPage)
    document.getElementById('lista-header').scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Fragment>
      <div className="home-lista__vehiculos">
        {
          vehicles.map((item, i) => (
            <Vehicle
              key={i}
              image={item.featured_image.full}
              name={item.model}
              price={item.price}
              version={item.version}
              year={item.year}
              km={item.kilometres}
              basis={''}
              link={`/usados/${item.id}`}
            />
          ))
        }
      </div>

      <div className="home-lista__pagination">
        <button
          className='home-lista__pagination__button'
          onClick={() => changePage(pagination.page - 1)}
          disabled={prevIsDisabled}
        >
          Anterior
        </button>
        <span>{pagination.page} de { pagination.total_pages }</span>
        <button
          className='home-lista__pagination__button'
          onClick={() => changePage(pagination.page + 1)}
          disabled={nextIsDisabled}
        >
          Siguiente
        </button>
      </div>
    </Fragment>
  );
};



export default HomeLista;
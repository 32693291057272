import { useState, useEffect } from 'react';
import axios from 'axios';

const useFecthUsados = () => {
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesPagination, setVehiclesPagination] = useState({
    page: 1,
    per_page: 8,
    total_pages: null,
    total: null,
  })
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const fetchData = async (page = 1) => {
    try {
      const { per_page } = vehiclesPagination
      const url = `https://doncar.com.ar/admin/wp-json/api/v1/used_cars?per_page=${per_page}&page=${page}`;
      const response = await axios.get(url);

      setVehicles(response.data);
      setVehiclesPagination({
        ...vehiclesPagination,
        total_pages: response.headers['x-wp-totalpages'],
        total: response.headers['x-wp-total'],
        page
      })
    } catch (error) {
      setHasError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  return {
    vehicles,
    vehiclesPagination,
    setVehiclesPagination,
    fetchData,
    isLoading,
    hasError
  };
};

export default useFecthUsados;
import React, { useState, useEffect } from 'react';
import DetalleCarouselImages from './DetalleCarouselImages';
import DetalleCarouselVideo from './DetalleCarouselVideo';

const DetalleCarousel = ({ usado }) => {
  const [mediaType, setMediaType] = useState('images');
  const [currentSlide, setCurrentSlide] = useState(0);

  const _next = () => {
    setCurrentSlide(currentSlide + 1);
  };
  const _prev = () => {
    setCurrentSlide(currentSlide - 1);
  };
  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const ShowImages = mediaType === 'images';

  return (
    <div className="detalle-body__carousel">
      {
        ShowImages ? (
          <DetalleCarouselImages
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
            carouselItems={usado.acf.images}
          />
        ) : (
          <DetalleCarouselVideo />
        )
      }

      <div className="media-buttons">
        <button className="button-option" onClick={() => setMediaType('images')}>
          <i className="fa-solid fa-image"></i>
          <span className='text-sm pl-1 hidden md:block'>
            Imágenes
          </span>
        </button>
        <button
          className="button-option" onClick={() => setMediaType('video')}
          style={{
            display: usado.acf.video ? 'flex' : 'none'
          }}
        >
          <i className="fa-brands fa-youtube"></i>
          <span className="text-sm pl-1 hidden md:block">
            Vídeos
          </span>
        </button>
      </div>

      <div className="navigation-buttons" style={{ display: ShowImages ? 'flex' : 'none' }}>
        <button disabled={currentSlide === 0} onClick={_prev} className="button-option">
          <i className="fa-regular fa-arrow-left"></i>
        </button>
        <button disabled={currentSlide === usado.acf.images.length - 1} onClick={_next} className="button-option">
          <i className="fa-regular fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};
export default DetalleCarousel;
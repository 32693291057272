import React from 'react';
import useDetalleForm from './useDetalleForm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DetalleForm = ({ usado }) => {

  const {
    nombre,
    email,
    telefono,
    modelo,
    provincia,
    cp,
    loading,
    handleInputChange,
    handleSubmit
  } = useDetalleForm({
    usadoModelo: usado.title.rendered
  });

  return (
    <div className="detalle-body__form">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />

      <h3 className="h3 form__title">
        ¿Estás interesado? <strong>¡Escribínos!</strong>
      </h3>

      <form className="form__container" id='usados-form' onSubmit={handleSubmit}>
        <input
          className='form__input'
          type="text"
          placeholder='Apellido y nombre'
          name='nombre'
          value={nombre}
          onChange={handleInputChange}
          required
        />
        <input
          className='form__input'
          type="email"
          placeholder='Correo electrónico'
          name='email'
          value={email}
          onChange={handleInputChange}
          required
        />
        <input
          className='form__input'
          type="text"
          placeholder='Teléfono'
          name='telefono'
          value={telefono}
          onChange={handleInputChange}
          required
        />
        <input
          className='form__input'
          type="text"
          name='modelo'
          placeholder='Modelo'
          value={modelo}
          onChange={handleInputChange}
          required
        />
        <input
          className='form__input'
          type="text"
          placeholder='Ubicación (Provincia)'
          name='provincia'
          value={provincia}
          onChange={handleInputChange}
          required
        />
        <input
          className='form__input'
          type="text"
          placeholder='Código Postal'
          name='cp'
          value={cp}
          onChange={handleInputChange}
          required
        />

        <button className="form__button" disabled={loading}>
          {loading ? 'Enviando...' : 'Enviar'}
        </button>
      </form>
    </div>
  );
};

export default DetalleForm;
import React, { Fragment, useState } from 'react';
import { isMobile } from '../../helpers/detectMobile';

const DetalleInfo = ({ usado, isLoading }) => {
  const [showDetails, setShowDetails] = useState(false);

  return isLoading ? (
    <div>
      Loading...
    </div>
  ) : (
    <div className="detalle-body__info">
      <h1 className="h2"><strong>{usado.title.rendered}</strong></h1>
      <h3 className="h3">
        <strong>
          {usado.acf.currency}{' '}
          {new Intl.NumberFormat('es-AR').format(usado.acf.amount)}
        </strong>
      </h3>

      <div className="f-light c-dark text-base">
        {usado.content.rendered}
      </div>

      <div className="detalle-body__info__table">
        <div className="table-title">Versión</div>
        <div className="table-value">{usado.acf.version}</div>

        <div className="table-title">Kilómetros</div>
        <div className="table-value">{usado.acf.kilometres}</div>

        <div className="table-title">Puertas</div>
        <div className="table-value">{usado.acf.doors}</div>

        <div className="table-title">Año</div>
        <div className="table-value">{usado.acf.year}</div>

        {
          showDetails ? (
            <Fragment>
              <div className="table-title">Transmisión</div>
              <div className="table-value">{usado.acf.transmission}</div>
            </Fragment>
          ) : ('')
        }
        {
          showDetails ? (
            <Fragment>
              <div className="table-title">Combustible</div>
              <div className="table-value">{usado.acf.fuel}</div>
            </Fragment>
          ) : ('')
        }
        {
          showDetails ? (
            <Fragment>
              <div className="table-title">Motor</div>
              <div className="table-value">{usado.acf.motor}</div>
            </Fragment>
          ) : ('')
        }
      </div>

      <button className="detalle-body__info__button" onClick={() => setShowDetails(!showDetails)}>
        {
          showDetails ? (
            <span>Ocultar características</span>
          ) : (
            <span>Ver más características</span>
          )
        }
      </button>

      <div className="detalle-body__info__contact">
        <a
          className='b-whatsapp'
          href='https://api.whatsapp.com/send?phone=+5493624488888'
          target='_blank' rel="noreferrer"
        >
          <i className="fa-lg fa-brands fa-whatsapp"></i>
          <span>Consultar por WhatsApp</span>
        </a>
        <a
          className='b-call'
          href='tel:+5493624488888'
          style={{ display: isMobile ? 'flex' : 'none' }}
        >
          <i className="fa-lg fa-light fa-phone"></i>
          <span>Llamar</span>
        </a>
      </div>
    </div>
  );
};

export default DetalleInfo;
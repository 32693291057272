import React from 'react';

const Footer = () => {
  return (
    <footer className="footer f-regular">
      <div className="container">
        <div className="footer__top">
          <div className="footer__top__logos">
            <div className="doncar">
              <img src="/images/doncar-logo-white.svg" alt="DonCar" />
            </div>
            <div className="rrss">
              <a href="https://www.facebook.com/VW.DonCar/" target="_blank" rel="noreferrer">
                <img src="/images/facebook.png" alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/doncar_vw/" target="_blank" rel="noreferrer">
                <img src="/images/instagram.png" alt="Instagram" />
              </a>
            </div>
          </div>
          <div className="footer__top__horarios">
            <h5 className='f-bold mb-4 uppercase'>Horarios de atención</h5>
            <div>
              <p className='f-light text-sm mb-2'>
                Lunes a Viernes:
                <br />
                8:30 a 12:30 hs y 15:30 a 20:00 hs.
              </p>
              <p className='f-light text-sm mb-2'>
                Sábados
                <br />
                9:00 a 12:30 hs.
              </p>
            </div>
          </div>
          <div className="footer__top__contacto">
            <h5 className='f-bold mb-4 uppercase'>Dirección y teléfono</h5>
            <div>
              <p className='f-light text-sm mb-2'>
                <a href="tel:0362 444-4166">0362 444-4166</a>
              </p>
              <p className='f-light text-sm mb-2'>
                <a href="https://goo.gl/maps/fdB9FVUnX3qXx6RW7" target="_blank" rel="noopener noreferrer">
                  Av. Sarmiento 1490, <br />
                  Ciudad de Resistencia (3500)
                  <br />
                  Provincia de Chaco
                </a>
              </p>
            </div>
          </div>
          <div className="footer__top__promociones">
            <h5 className='f-bold mb-4 uppercase'>Promociones y descuentos</h5>
            <div>
              <p className='f-light text-sm mb-2'>
                Suscribite a nuestro boletín y recibí las promociones que tenemos para vos
              </p>
              <input type="text" placeholder='Correo electrónico' />
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <span>
            © DonCar 2022
          </span>
          <span>
            <img src="/images/vw-logo-white.svg" alt="VolksWagen" />
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
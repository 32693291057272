import React from "react";

function Testimonials(){
  return(
    <section className="testimonials lg:flex">
      <div className="testimonials__text lg:basis-1/4">
        <h3 className="h2 md:text-left f-light c-light">
          ¿Qué dijeron<br />nuestros clientes?
        </h3>
        {/* <p className="f-light">
          Nuestro objetivo es brindar una experiencia de compra bla bla. Pellentesque ut placerat nunc. Proin sit amet estibi. Maecenas elit velit.
        </p> */}
      </div>
      <div className="testimonials__list block lg:flex lg:basis-3/4 lg:overflow-auto xl:overflow-hidden">
        <div className="testimonials__list-item bg-dark radius-top-small radius-bottom-small text-center">
          <p className="testimonials__list-item--text c-white f-light relative">
            “Viverra sit aliquam neque, ipsum dgsdiam. In urna pellentesdque sed id. Ac mus aliqusam aliquam facilisi accumsan nisi, laoreet adipisddcing. Montes molestie urna pelaslentesque."
          </p>
          <h5 className="testimonials__list-item--name f-regular c-light">
            Fernando Nicolás Castillo
          </h5>
          <figure className="testimonials__list-item--icon">
            <img className="mx-auto" src="../images/testimonials--icon.png" />
          </figure>
          <p className="testimonials__list-item--used c-white f-regular">
            ES FELIZ CON SU
            <br />
            VOLKSWAGEN POLO
          </p>
        </div>

        <div className="testimonials__list-item bg-dark radius-top-small radius-bottom-small text-center">
          <p className="testimonials__list-item--text c-white f-light relative">
            “Viverra sit aliquam neque, ipsum dgsdiam. In urna pellentesdque sed id. Ac mus aliqusam aliquam facilisi accumsan nisi, laoreet adipisddcing. Montes molestie urna pelaslentesque."
          </p>
          <h5 className="testimonials__list-item--name f-regular c-light">
            Fernando Nicolás Castillo
          </h5>
          <figure className="testimonials__list-item--icon">
            <img className="mx-auto" src="../images/testimonials--icon.png" />
          </figure>
          <p className="testimonials__list-item--used c-white f-regular">
            ES FELIZ CON SU
            <br />
            VOLKSWAGEN POLO
          </p>
        </div>

        <div className="testimonials__list-item bg-dark radius-top-small radius-bottom-small text-center">
          <p className="testimonials__list-item--text c-white f-light relative">
            “Viverra sit aliquam neque, ipsum dgsdiam. In urna pellentesdque sed id. Ac mus aliqusam aliquam facilisi accumsan nisi, laoreet adipisddcing. Montes molestie urna pelaslentesque."
          </p>
          <h5 className="testimonials__list-item--name f-regular c-light">
            Fernando Nicolás Castillo
          </h5>
          <figure className="testimonials__list-item--icon">
            <img className="mx-auto" src="../images/testimonials--icon.png" />
          </figure>
          <p className="testimonials__list-item--used c-white f-regular">
            ES FELIZ CON SU
            <br />
            VOLKSWAGEN POLO
          </p>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
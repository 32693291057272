import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from 'react-responsive-carousel'
import Vehicle from './Vehicle'

function Slider({ related, used }) {

  if (related) {
    return (
      <>
        <Carousel
          className='max-w-full'
          autoPlay={false}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          renderArrowPrev={
            (clickHandler, label) => (
              <button
                type="button"
                className='arrow-button prev'
                onClick={clickHandler}
                title={label}
              >
                <img src="../images/header__prev-btn--dark.png" alt="Prev" />
              </button>
            )
          }
          renderArrowNext={
            (clickHandler, label) => (
              <button
                type="button"
                className='arrow-button next'
                onClick={clickHandler}
                title={label}
              >
                <img src="../images/header__next-btn--dark.png" alt="Next" />
              </button>
            )
          }
        >
          {
            used && used.map((item, index) => (
              index < 3 &&
              <Vehicle
                key={index}
                image={item.featured_image.full}
                name={item.model}
                price={item.price}
                version={item.version}
                link={'#'}
                basis={'car--carousel text-left'}
              />
            ))
          }
        </Carousel>
      </>
    )
  } else {
    return (
      <>
        <Carousel
          className='max-w-xs'
          autoPlay={false}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          renderArrowPrev={
            (clickHandler, label) => (
              <button
                type="button"
                className='arrow-button prev'
                onClick={clickHandler}
                title={label}
              >
                <img src="../images/header__prev-btn.png" alt="Prev" />
              </button>
            )
          }
          renderArrowNext={
            (clickHandler, label) => (
              <button
                type="button"
                className='arrow-button next'
                onClick={clickHandler}
                title={label}
              >
                <img src="../images/header__next-btn.png" alt="Next" />
              </button>
            )
          }
        >
          <div className='informations__items'>
            <figure className='rounded-full flex m-auto items-center'>
              <img
                className='m-auto'
                src="../images/compra-segura.png"
                alt="Compra segura"
              />
            </figure>
            <div className='text'>
              <h4 className='h4'>
                <strong>Compra segura</strong>
              </h4>
              <p className='f-light'>
                Viverra sit aliquam neque, ipsum diam.
                In urna pellentesque sed id ac mus.
              </p>
            </div>
          </div>
          <div className='informations__items'>
            <figure className='rounded-full flex m-auto items-center'>
              <img
                className='m-auto'
                src="../images/promociones.png"
                alt="Promociones"
              />
            </figure>
            <div className='text'>
              <h4 className='h4'>
                <strong>Promociones</strong>
              </h4>
              <p className='f-light'>
                Viverra sit aliquam neque, ipsum diam.
                In urna pellentesque sed id ac mus.
              </p>
            </div>
          </div>
          <div className='informations__items'>
            <figure className='rounded-full flex m-auto items-center'>
              <img
                className='m-auto'
                src="../images/documentacion-al-dia.png"
                alt="Documentación al día"
              />
            </figure>
            <div className='text'>
              <h4 className='h4'>
                <strong>Documentación al día</strong>
              </h4>
              <p className='f-light'>
                Viverra sit aliquam neque, ipsum diam.
                In urna pellentesque sed id ac mus.
              </p>
            </div>
          </div>
        </Carousel>
      </>
    )
  }

}

export default Slider
import React from 'react'

function CallToActions() {
  return (
    <>
      <div className='cta flex flex-nowrap overflow-x-auto lg:overflow-x-clip'>
        <div className='cta__item cta__item--hover basis-1/3'>
          <div className='cta__item__title'>
            <h4 className='h4 c-white'>¿Querés <strong>vender</strong><br />tu usado?</h4>
          </div>
          <div className='cta__item__body'>
            <picture className='overflow-hidden block radius-top-small radius-bottom-small'>
              <img src="../images/img-vender-auto-usado.jpeg" className='max-w-full' alt='Vender auto usado' />
            </picture>
            <p className='f-light c-white'>
              Traenos tu vehículo para peritarlo y corroborar el estado de la unidad.
              Le damos a tu auto o camioneta el valor que merece.
            </p>
            <button className='btn btn--light bg-light c-white f-regular w-full rounded-full'>Comprar</button>
          </div>
        </div>
        <div className='cta__item cta__item--hover basis-1/3 mx-[30px] md:mx-[60px] 2xl:mx-[80px]'>
          <div className='cta__item__title'>
            <h4 className='h4 c-white'>¿Querés <strong>comprar</strong><br />tu usado?</h4>
          </div>
          <div className='cta__item__body'>
            <picture className='overflow-hidden block radius-top-small radius-bottom-small'>
              <img src="../images/img-comprar-auto-usado.jpeg" className='max-w-full' alt='Vender auto usado' />
            </picture>
            <p className='f-light c-white'>
            Tenemos precios competitivos y excelente financiación para que puedas llegar a tu usado.
            </p>
            <button className='btn btn--light bg-light c-white f-regular w-full rounded-full'>Comprar</button>
          </div>
        </div>
        <div className='cta__item basis-1/3 mr-[30px] md:mr-0'>
          <div className='cta__item__title'>
            <h4 className='h4 c-white'>¿Tenés <strong>consultas</strong><br />sobre un usado?</h4>
          </div>
        </div>
      </div>
    </>
  )
}

export default CallToActions
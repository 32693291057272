import React from 'react';
import { Carousel } from 'react-responsive-carousel';

const DetalleCarouselImages = ({ selectedItem, updateCurrentSlide, carouselItems }) => {
  return (
    <div className="images">
      <Carousel
        showIndicators={false}
        showArrows={false}
        showStatus={false}
        selectedItem={selectedItem}
        onChange={updateCurrentSlide}
        dynamicHeight={false}
        showThumbs={false}
        swipeable={false}
        width='100%'
      >
        {
          carouselItems.map((item, key) => (
            <img key={key} src={item.url} alt='carousel' loading='lazy' width='100%' />
          ))
        }
      </Carousel>
    </div>
  );
};

export default DetalleCarouselImages;
import React from 'react'

const Vehicle = ({
  image,
  name,
  price,
  version,
  year,
  km,
  link,
  basis
}) => {
  return (
    <>
      <div className={`car ${basis}`}>
        <div className='car__image overflow-hidden radius-top-small'>
          <img
            src={image}
            className='object-cover object-center w-full h-full block'
            alt={name}
            title={name}
          />
        </div>
        <div className='car__content bg-white'>
          <h4 className='car__content__name h4 c-dark'>
            <strong>{name}</strong>
          </h4>
          <h3 className='car__content__price h3 c-light'>
            <strong>{price}</strong>
          </h3>
          <div className='line h-[2px] bg-gray-light'></div>
          <ul className='car__content__details c-dark'>
            <li className='c-dark'>
              <span className='lavel block f-bold'>VERSIÓN</span>
              <span className='value f-light block'>{version}</span>
            </li>
            {
              year && 
              <li className='c-dark'>
                <span className='lavel block f-bold'>AÑO</span>
                <span className='value f-light block'>{year}</span>
              </li>
            }
            {
              km && 
              <li className='c-dark'>
                <span className='lavel block f-bold'>KILÓMETROS</span>
                <span className='value f-light block'>{km}</span>
              </li>
            }
          </ul>
        </div>
        <div className='car__footer bg-light c-white text-center hover:bg-dark radius-bottom-small cursor-pointer'>
          <a className='' href={link}><p className=''>Más detalles</p></a>
        </div>
      </div>
    </>
  )
}

export default Vehicle;
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const colorBlueDark = '#001F4E';
  const colorWhite = '#fff';

  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [navbarStyles, setNavbarStyles] = useState(
    {
      background: 'linear-gradient(105.18deg, #00437a 0%, #001e50 100%)',
      color: colorWhite,
    }
  );
  const menuStyles = {
    transform: menuIsVisible ? 'translateX(0)' : 'translateX(-100%)',
  };
  const handleMenuVisibility = (status) => {
    setMenuIsVisible(status);
  };

  return (
    <nav className="navbar" style={navbarStyles}>
      <div className="navbar-content">
        <div className="navbar-logo">
          <Link to="/">
            <img src="/images/doncar-logo-white.svg" alt="DonCar" />
          </Link>
        </div>
        <div className="navbar-search">
          <button>
            <img src='/images/search-white.svg' alt="Buscar" />
          </button>
        </div>
        <div onClick={() => handleMenuVisibility(true)} className="navbar-hamburguer">
          <button>
            <img src="/images/menu-white.svg" alt="Menú" />
          </button>
        </div>
      </div>
      {/*  */}
      <div className="navbar-lines">
        <div className="line"></div>
        <div className="logo">
          <img src="/images/vw-logo-white.svg" alt="VW" />
        </div>
        <div className="line"></div>
      </div>
      {/*  */}
      <div className="navbar-menu" style={menuStyles}>
        <div className="navbar-menu__container container">
          <button onClick={() => handleMenuVisibility(false)} className='navbar-menu__close'>
            <img src="/images/menu-close-white.svg" alt="Cerrar" />
          </button>

          <div className="navbar-menu__container--left">
            <div className="navbar-menu__title">
              <h3 className='h3 h3-title'>¡Gracias por tu interés!</h3>
              <h3 className='h3 h3-subtitle'>En este sitio podés</h3>
            </div>
            <div className="navbar-menu__links">
              <Link className='navbar-link' to="/">Comprar un usado</Link>
              <Link className='navbar-link' to="/">Vender un usado</Link>
              <Link className='navbar-link' to="/">Ver clientes satisfechos</Link>
            </div>
          </div>
          <div className="navbar-menu__container--right">
            <div className="item">
              <div className="image">
                <img src="/images/navbar/compra-segura.png" alt="Compra Segura" />
              </div>
              <div className="text">
                <h4 className="text__h4 mb-1 text-white">Compra Segura</h4>
                <p className="text__p f-light">Comprá de manera segura conociendo cada detalle antes de realizar la compra de tu vehículo.</p>
              </div>
            </div>
            {/*  */}
            <div className="item">
              <div className="image">
                <img src="/images/navbar/promociones.png" alt="Promociones" />
              </div>
              <div className="text">
                <h4 className="text__h4 mb-1 text-white">Promociones</h4>
                <p className="text__p f-light">Aprovecha nuestras financiaciones y tasas súper convenientes. Consultá financiación del NBCH.</p>
              </div>
            </div>
            {/*  */}
            <div className="item">
              <div className="image">
                <img src="/images/navbar/garantia.png" alt="Garantía" />
              </div>
              <div className="text">
                <h4 className="text__h4 mb-1 text-white">Documentación al día</h4>
                <p className="text__p f-light">Tené la confianza de que la documentación del vehículo que vas a llevar se encuentra 100% al día.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

const PHP_URL = 'https://doncar.com.ar/views/ajax/sendForm-usados.php';

const useDetalleForm = ({ usadoModelo }) => {
  const initialState = {
    nombre: '', // nombre y apellido
    apellido: '', // !
    email: '', //
    provincia: '', // ubicación
    cp: '', // !
    modelo: usadoModelo,
    version: '',
    telefono: '', //
    consulta: '', // !
    patente: '', // ?
    loading: false,
  };

  const [
    {
      nombre,
      apellido,
      email,
      provincia,
      cp,
      modelo,
      version,
      telefono,
      consulta,
      patente,
      loading,
    },
    setState
  ] = useState(initialState);

  const resetState = () => {
    setState({ ...initialState });
  };

  const handleInputChange = event => {
    const _input = event.target.name;
    let _value = event.target.value;

    setState(prevState => ({ ...prevState, [_input]: _value }));
  };

  const handleSubmit = async event => {
    if (event) { event.preventDefault(); };
    console.log("submission prevented");

    setState(prevState => ({ ...prevState, loading: true }));

    const fd = new FormData();
    fd.append('nombre', nombre);
    fd.append('apellido', apellido);
    fd.append('email', email);
    fd.append('provincia', provincia);
    fd.append('cp', cp);
    fd.append('modelo', modelo);
    fd.append('version', version);
    fd.append('telefono', telefono);
    fd.append('consulta', consulta);
    fd.append('patente', patente);

    try {
      const res = await axios.get(PHP_URL, fd);

      if (res.data === 'success') {
        toast.success('Formulario enviado con éxito!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        resetState();
        document.getElementById('usados-form').reset();
      }
    } catch (error) {
      console.log(error);
      toast.error('Hubo un error al enviar su formulario', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }



  };

  return {
    nombre,
    email,
    telefono,
    provincia,
    cp,
    modelo,
    loading,
    resetState,
    handleInputChange,
    handleSubmit,
  };
};

export default useDetalleForm;
import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';

const HomeCarouseItem = ({ image, button, hasButton, title, arrows }) => {
  return (
    <div className="home-carousel__item">
      <div className="home-carousel__item__content">
        <h1 className='h1 f-light'
          dangerouslySetInnerHTML={{__html: title}}>
        </h1>

        <div className="w-full flex py-4 justify-between">
          {
            hasButton ? (
              <a
                className="carousel-button"
                href={button.button_link}
                target='_blank' rel="noreferrer"
              >
                {button.button_text}
              </a>
            ) : ''
          }

          {arrows}
        </div>
      </div>

      <img
        className='home-carousel__item__img'
        src={image.full}
        loading='lazy'
        alt='Encabezado'
      />
    </div>
  );
};

const HomeCarousel = () => {
  const [carouselItems, setCarouselItems] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const _next = () => {
    setCurrentSlide(currentSlide + 1);
  };
  const _prev = () => {
    setCurrentSlide(currentSlide - 1);
  };
  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      if (index === carouselItems.length - 1) {
        setCurrentSlide(0);
      } else if (index === 0) {
        setCurrentSlide(carouselItems.length - 1)
      } else {
        setCurrentSlide(index);
      }
    }
  };

  useEffect(() => {
    const fetchCarouselItems = async () => {
      try {
        const response = await axios.get('https://doncar.com.ar/admin/wp-json/api/v1/carousel');

        setCarouselItems(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCarouselItems();
  }, []);

  return (
    <div className="home-carousel">
      <Carousel
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        showThumbs={false}
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
      >
        {
          carouselItems.map(((carouselItem, i) => (
            <HomeCarouseItem
              key={i}
              image={carouselItem.image}
              hasButton={carouselItem.button_enabler}
              button={carouselItem.button}
              title={carouselItem.title}
              arrows={
                <div className="arrows ml-auto">
                  <button onClick={_prev} className="carousel-button rounded-full">{'<'}</button>
                  <button onClick={_next} className="carousel-button rounded-full">{'>'}</button>
                </div>
              }
            />
          )))
        }
      </Carousel>
    </div >
  );
};

export default HomeCarousel;
import React from 'react'
import useWindowDimensions from '../Dimensions';
import Slider from '../Slider';

function Informations() {

  const { width } = useWindowDimensions();

  return (
    <>
      {
        width <= 768
          ?
          <div className='informations bg-light c-white flex flex-col justify-center radius-top-small radius-bottom-small overflow-hidden m-auto'>
            <Slider />
          </div>
          :
          <div className='informations bg-light c-white flex'>
            <div className='informations__items flex content-center basis-1/3'>
              <figure className='rounded-full flex items-center'>
                <img
                  className='m-auto'
                  src="../images/compra-segura.png"
                  alt="Compra segura"
                />
              </figure>
              <div className='text'>
                <h4 className='h4'>
                  Compra segura
                </h4>
                <p className='f-light'>
                  Comprá de manera segura conociendo cada detalle antes de realizar la compra de tu vehículo.
                </p>
              </div>
            </div>
            <div className='informations__items flex content-center basis-1/3'>
              <figure className='rounded-full flex items-center'>
                <img
                  className='m-auto'
                  src="../images/promociones.png"
                  alt="Promociones"
                />
              </figure>
              <div className='text'>
                <h4 className='h4'>
                  Promociones
                </h4>
                <p className='f-light'>
                  Aprovecha nuestras financiaciones y tasas súper convenientes. Consultá financiación del NBCH.
                </p>
              </div>
            </div>
            <div className='informations__items flex content-center basis-1/3'>
              <figure className='rounded-full flex items-center'>
                <img
                  className='m-auto'
                  src="../images/documentacion-al-dia.png"
                  alt="Documentación al día"
                />
              </figure>
              <div className='text'>
                <h4 className='h4'>
                  Documentación al día
                </h4>
                <p className='f-light'>
                  Tené la confianza de que la documentación del vehículo que vas a llevar se encuentra 100% al día.
                </p>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default Informations